import { requestApi_post, requestApi_get } from '../../lib/api';
import { toastLoading, closeToast, toastSuccess } from '../../lib/util'

const initFormat = {
    collectProductData: {
        preview_image: '',
        label_list: []
    },
    labels: {
        label_all_num: '',
        label_none_num: '',
        label_list: [],
    },
    collectData: {
        total_pages: '',
        list: []
    }
}

const state = {
    collectProductData: initFormat.collectProductData,
    labels: initFormat.labels,
    collectData: initFormat.collectData,
    tags: [],
}

const actions = {
    //收藏
    requestCollectWord({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading('收藏中...');
            requestApi_post('/collect/collect_works', params).then(() => {
                closeToast();
                resolve()
            })
        })
    },

    //取消收藏
    requestCollectCancel({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading('请稍等...');
            requestApi_post('/collect/collect_cancel', params).then(() => {
                closeToast();
                toastSuccess('取消成功', true)
                resolve()
            })
        })
    },

    //获取已收藏的作品所属画板
    requestResetInitLabels({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/collect/reset_init_labels', params).then(res => {
                commit('SET_COLLECT_PRODUCT_DATA', res)
                resolve()
            })
        })
    },

    //收藏作品后设置画板
    requestLabelSetting({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading('提交中...');
            requestApi_post('/collect/label_setting', params).then(() => {
                closeToast();
                toastSuccess('设置成功', true).then(() => {
                    resolve()
                })

            })
        })
    },

    //添加画板
    requestLabelAdd({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading('提交中...');
            requestApi_post('/collect/label_add', params).then(() => {
                closeToast();
                toastSuccess('添加成功', true).then(() => {
                    resolve()
                })
            })
        })
    },

    //删除画板
    requestLabelDel({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading('提交中...');
            requestApi_post('/collect/label_del', params).then(() => {
                closeToast();
                toastSuccess('删除成功', true).then(() => {
                    resolve()
                })
            })
        })
    },

    //编辑画板
    requestLabelEdit({ commit }, params = {}) {
        return new Promise(resolve => {
            toastLoading('提交中...');
            requestApi_post('/collect/label_edit', params).then(() => {
                closeToast();
                toastSuccess('编辑成功', true).then(() => {
                    resolve()
                })
            })
        })
    },

    // 获取所有画板
    requestLabels({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/collect/labels', params).then(res => {
                commit('SET_LABELS', res)
                resolve()
            })
        })
    },

    // 获取画板所对应的作品
    requestCollections({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/collect/index', params).then(res => {
                commit('SET_COLLECT_DATA', res)
                resolve()
            })
        })
    },

    // 查询用户所有添加的画板
    requestUserLabels({ commit }, params = {}) {
        return new Promise(resolve => {
            requestApi_get('/collect/user_labels', params).then(res => {
                commit('SET_TAGS', res)
                resolve();
            })
        })
    }
}



const mutations = {
    SET_COLLECT_PRODUCT_DATA(state, data) {
        state.collectProductData = data
    },
    SET_TAGS_CHECK(state, data) {
        state.collectProductData.label_list[data.index].checked = data.checked
    },
    SET_LABELS(state, data) {
        state.labels = data;
    },
    SET_COLLECT_DATA(state, data) {
        state.collectData = data;
    },
    SET_TAGS(state, data) {
        state.tags = data;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}